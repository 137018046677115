<script lang="ts">
import useTailwindConfig from '@/composables/helpers/useTailwindConfig';

const { theme } = useTailwindConfig();
</script>

<script lang="ts" setup>
import { Line } from 'vue-chartjs';
import type { ChartData, ChartOptions, Plugin } from 'chart.js';
import type { DataSet } from '@/types/charts';
import ChartLegend from '@/components/common/ChartLegend.vue';

interface Props {
  labels: string[];
  datasets: DataSet[];
  displayLegend?: boolean;
  backgroundColor?: string;
  gridColor?: string;
  lineColor?: string;
  lineWidth?: number;
  ticksColor?: string;
  ticksFontSize?: number;
  className?: string;
  minY?: number;
  maxY?: number;
  stepSizeY?: number;
  autoSkip?: boolean;
  autoSkipPadding?: number;
  plugins?: Plugin[];
  xTicksPadding?: number;
  yTicksPadding?: number;
  yTicksCallback?: (value: string | number) => string | number;
  xTicksCallback?: (value: string | number) => string | number;
}

const props = withDefaults(defineProps<Props>(), {
  backgroundColor: theme.colors.white,
  gridColor: theme?.colors['brand-navy']['50'],
  lineColor: theme?.colors['brand-light-gray'],
  lineWidth: 4,
  ticksColor: theme?.colors['brand-navy']['200'],
  ticksFontSize: 14,
  stepSizeY: 1,
  autoSkip: true,
  autoSkipPadding: 3,
  xTicksPadding: 5,
  yTicksPadding: 10,
  plugins: () => []
});

const chartData = computed<ChartData>(() => {
  return {
    labels: props.labels,
    datasets: props.datasets.map((dataset) => ({
      data: dataset.data,
      borderColor: dataset.borderColor,
      order: dataset.order,
      fill: false,
      tension: 0,
      clip: false
    }))
  };
});

const chartOptions = computed<ChartOptions>(() => {
  return {
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    events: [
      /* To disable re-render in 'afterDatasetsDraw' on hover */
    ],
    layout: {
      padding: {
        top: -5,
        right: 0,
        bottom: -5,
        left: -5
      }
    },
    elements: {
      line: {
        borderWidth: props.lineWidth,
        borderColor: props.lineColor
      },
      point: {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        radius: 0,
        hoverRadius: 0
      }
    },
    scales: {
      x: {
        offset: true,
        grid: {
          display: false
        },
        border: {
          display: false
        },
        ticks: {
          padding: props.xTicksPadding,
          color: props.ticksColor,
          labelOffset: 0,
          font: {
            size: props.ticksFontSize
          },
          ...(props.xTicksCallback
            ? {
                callback: props.xTicksCallback
              }
            : {})
        }
      },
      y: {
        min: props.minY,
        max: props.maxY,
        grid: {
          tickLength: -10,
          tickColor: props.backgroundColor,
          color: props.gridColor
        },
        border: {
          display: false
        },
        ticks: {
          stepSize: props.stepSizeY,
          padding: props.yTicksPadding,
          color: props.ticksColor,
          autoSkip: props.autoSkip,
          autoSkipPadding: props.autoSkipPadding,
          font: {
            size: props.ticksFontSize,
            lineHeight: 1
          },
          ...(props.yTicksCallback
            ? {
                callback: props.yTicksCallback
              }
            : {})
        }
      }
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: false
      }
    }
  };
});

const customCanvasBackgroundColor: Plugin = {
  id: 'customCanvasBackgroundColor',
  beforeDraw(chart, args, options) {
    const { ctx } = chart;
    ctx.save();
    ctx.globalCompositeOperation = 'destination-over';
    ctx.fillStyle = options.color || props.backgroundColor;
    ctx.fillRect(0, 0, chart.width, chart.height);
    ctx.restore();
  }
};

const chartPlugins = computed<Plugin[]>(() => {
  return [customCanvasBackgroundColor, ...props.plugins];
});
</script>
<template>
  <div
    :class="[
      'w-full h-full',
      displayLegend && 'grid gap-1 grid-rows-[1fr_min-content] items-start',
      className
    ]"
  >
    <div :class="['h-full', displayLegend && 'self-stretch']">
      <Line :data="chartData" :options="chartOptions" :plugins="chartPlugins" />
    </div>
    <ChartLegend
      v-if="displayLegend"
      :datasets="datasets"
      :background-color="backgroundColor"
      :line-color="lineColor"
      :line-width="lineWidth"
    />
  </div>
</template>
