<script lang="ts" setup></script>

<template>
  <div
    :class="[
      'absolute flex flex-col justify-center items-center w-full h-full z-10',
      'backdrop-blur-sm bg-white/20',
      'text-brand-navy-700'
    ]"
  >
    <p class="text-center text-lg leading-6 font-medium max-w-72">
      Your stats will be updated after the current tournament ends
    </p>
  </div>
</template>

<style lang="scss" scoped></style>
