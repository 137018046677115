<script lang="ts">
import useTailwindConfig from '@/composables/helpers/useTailwindConfig';

const { theme } = useTailwindConfig();
</script>

<script lang="ts" setup>
import type { DataSet } from '@/types/charts';

interface Props {
  datasets: DataSet[];
  backgroundColor?: string;
  lineColor?: string;
  lineWidth?: number;
}

withDefaults(defineProps<Props>(), {
  backgroundColor: theme.colors.white,
  lineColor: theme?.colors['brand-light-gray'],
  lineWidth: 4
});
</script>

<template>
  <ul
    class="flex flex-wrap justify-end items-center"
    :style="{ backgroundColor }"
  >
    <li
      v-for="(dataset, index) in datasets"
      :key="index"
      class="flex items-center ml-6 first:ml-0 mt-3"
    >
      <span
        class="inline-block w-8 h-1 rounded-full mr-2.5"
        :style="{
          backgroundColor: dataset.borderColor || lineColor,
          height: `${lineWidth}px`
        }"
      />
      <span class="text-brand-navy-200 text-base leading-5 font-medium">
        {{ dataset.label }}
      </span>
    </li>
  </ul>
</template>
