<script lang="ts" setup>
interface Props {
  value: number;
  colorClass?: string;
  className?: string;
}

const props = withDefaults(defineProps<Props>(), {
  colorClass: 'bg-brand-red-200'
});

const style = computed(() => {
  return `width: ${props.value}%`;
});
</script>

<template>
  <div :class="['h-3.5 w-full bg-brand-light-gray rounded-full', className]">
    <div :class="['h-full rounded-full', colorClass]" :style="style" />
  </div>
</template>
